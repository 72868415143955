import styled from '@emotion/styled';
import { breakpoints } from '~/theme';
import { Text } from '$shared/components/Text';
import { Headline } from '../Headline';
import { ifProp } from 'styled-tools';
import { M140ProductListContentLayout } from '~/lib/data-contract';

export const StyledHeadline = styled(Headline)(({ theme }) => ({
    marginBottom: theme.spaces[7],
}));

export const StyledSelectedFacetsBar = styled.div(({ theme }) => ({
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    marginBottom: theme.spaces[7],
    gap: theme.spaces[7],
}));

export const StyledProductsContainer = styled.div(() => ({
    width: '100%',
}));

export const StyledProductsGrid = styled.ul<{ layout: M140ProductListContentLayout }>(
    ifProp({ layout: 'Cards' }, ({ theme }) => ({
        willChange: 'transform',
        display: 'grid',

        gap: `${theme.spaces[4]} ${theme.spaces[3]}`,

        [breakpoints.s]: {
            gap: `${theme.spaces[6]} ${theme.spaces[5]}`,
            gridTemplateColumns: 'repeat(2, 1fr)',
        },
        [breakpoints.md]: {
            gridTemplateColumns: 'repeat(3, 1fr)',
            gap: `${theme.spaces[8]} ${theme.spaces[7]}`,
        },
        [breakpoints.lg]: {
            gridTemplateColumns: 'repeat(3, 1fr)',
        },
    })),
    ifProp({ layout: 'List' }, () => ({
        display: 'grid',
        gridTemplateColumns: 'repeat(1, 1fr)',
    })),
);

export const StyledNoResultsText = styled(Text)(() => ({
    gridColumn: 'span 3',
}));

export const StyledProductGridItem = styled.li(({ theme }) => ({
    position: 'relative',
    [breakpoints.md]: {
        [':nth-of-type(3n+2)']: {
            top: theme.spaces[7],
        },
    },
}));

export const StyledProductOptions = styled.li(({ theme }) => ({
    position: 'absolute',
    padding: `${theme.spaces[5]} ${theme.spaces[6]} ${theme.spaces[2]} ${theme.spaces[2]}`,
    top: 0,
    right: 0,
}));

export const StyledHeadlineRow = styled.div(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    marginBottom: theme.spaces[4],

    [breakpoints.sm]: {
        flexDirection: 'row',
        alignItems: 'flex-end',
        marginBottom: theme.spaces[6],
    },

    [`${Headline}`]: {
        [breakpoints.sm]: {
            marginRight: 'auto',
            marginBottom: 0,
        },
    },
}));

export const StyledLayoutToggle = styled.div<{ active: 'left' | 'right' }>(
    ({ theme }) => ({
        position: 'relative',
        borderRadius: theme.borderRadius.input,
        backgroundColor: theme.colors.grey05,
        marginLeft: 'auto',
        [':before']: {
            backgroundColor: theme.colors.grey60,
            content: '""',
            position: 'absolute',
            zIndex: theme.zIndexes[1],
            width: 55,
            height: 48,
            borderRadius: theme.borderRadius.input,
            left: 0,
            transform: 'translateX(0%)',
            transition: `transform ${theme.animations.timingLong} ${theme.animations.easeOutCirc}`,
        },
    }),
    ifProp({ active: 'right' }, () => ({
        [':before']: {
            transform: 'translateX(100%)',
        },
    })),
);

export const StyledLayoutToggleInput = styled.input(() => ({
    clip: 'rect(0 0 0 0)',
    clipPath: 'inset(100%)',
    height: 1,
    overflow: 'hidden',
    position: 'absolute',
    whiteSpace: 'nowrap',
    width: 1,
}));

export const StyledLayoutToggleOption = styled.label<{ active: boolean }>(
    ({ theme }) => ({
        width: 55,
        height: 48,
        display: 'inline-flex',
        alignItems: 'center',
        justifyContent: 'center',
        position: 'relative',
        zIndex: theme.zIndexes[2],
        cursor: 'pointer',
        transition: `color ${theme.animations.timingLong} ${theme.animations.easeOutCirc}`,
        borderRadius: theme.borderRadius.input,
    }),
    ifProp({ active: true }, ({ theme }) => ({
        figure: {
            color: theme.colors.white,
        },
    })),
);
